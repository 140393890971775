<template>
<div>

    <v-container>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <s-toolbar :color="'#244093'" dark label="Reporte Recursos H." class="tran"></s-toolbar>
                    <v-row style="margin: auto">
                        <v-card width="100%">

                            <v-row justify="center" style="margin-top: 4px; margin-left: 10px; margin: auto">
                                <v-col cols="4" lg="4" md="4">
                                    <s-select-definition :def="1131" v-model="objMounth" return-object label="Seleccione mes"></s-select-definition>
                                </v-col>
                                <v-col cols="4" lg="4" md="4">
                                    <s-text label="Ingrese año" number v-model="txtYear"></s-text>
                                </v-col>
                                <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                                    <v-btn rounded :color="'info'" small @click="run()">Buscar</v-btn>
                                    <v-btn rounded :color="'error'" small @click="downloadOne()">Descargar</v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-row>
                </v-col>
            </v-row>

            <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
                <v-card color="primary" dark>
                    <v-card-text v-if="messageProcessing.length == 0">
                        Por favor espere
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                    <v-card-text v-else>
                        <div class="text--white pt-4">
                            Error al realizar busqueda <br />
                            {{ messageProcessing }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
        <v-container>
            <v-tabs v-model="currentItem">
                <v-tab href="#CV"> Reporte RRHH CV</v-tab>
                <v-tab href="#RE"> Reporte RRHH RE</v-tab>
            </v-tabs>

            <v-tabs-items v-model="currentItem">
                <v-tab-item :value="'CV'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="itemsCV.length > 0 ? '350px': 'auto'" :headers="headersCV" :items="itemsCV" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 200],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <v-tab-item :value="'RE'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="itemsRE.length > 0 ? '350px': 'auto'" :headers="headersRE" :items="itemsRE" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 200],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

            </v-tabs-items>
        </v-container>
    </v-container>
</div>
</template>

<script>
import _sClcService from "@/services/ReportClicks/ClcChargeMasive.js";
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import * as XLSX from 'xlsx';

export default {
    components: {},

    data() {
        return {
            currentItem: "tab-Funciones",
            processing: false,
            messageProcessing: "",
            objMounth: null,
            txtYear: this.$fun.getYear(),
            itemsCV: [],
            itemsRE: [],
            headersCV: [],
            headersRE: [],
        }

    },

    methods: {

        run() {
            this.runView()
            this.runView2()
        },

        runView() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "Month, Year"
            report.QryParamsArrayValue = this.objMounth.DedDescription + ', ' + this.txtYear //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportHumanResourcesCv_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsCV = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.itemsCV[0]).length; i++
                            ) {
                                this.headersCV.splice(i, i, {
                                    text: Object.keys(this.itemsCV[0])[i],
                                    value: Object.keys(this.itemsCV[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        runView2() {
            this.messageProcessing = ""
            var report = {}
            report.QryNameProcedure = "";
            report.QryParamsArray = "Month, Year"
            report.QryParamsArrayValue = this.objMounth.DedDescription + ', ' + this.txtYear //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportHumanResourcesRe_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.itemsRE = resp.data;

                          /*   console.log("RE",this.itemsRE) */

                            for (
                                var i = 0; i < Object.keys(this.itemsRE[0]).length; i++
                            ) {
                                this.headersRE.splice(i, i, {
                                    text: Object.keys(this.itemsRE[0])[i],
                                    value: Object.keys(this.itemsRE[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

         downloadOne() {

            const workSheet1 = XLSX.utils.json_to_sheet(this.itemsCV);
            const workSheet2 = XLSX.utils.json_to_sheet(this.itemsRE);


            const workBook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workBook, workSheet1, "CV");
            XLSX.utils.book_append_sheet(workBook, workSheet2, "RE");
            //buffer
            let buffer = XLSX.write(workBook, {
                bookType: "xlsx",
                type: "buffer"
            });

            //binary
            XLSX.write(workBook, {
                bookType: "xlsx",
                type: "binary"
            });
            //donwload
            XLSX.writeFile(workBook, "ReporteRRHH.xlsx");

        },

    }
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
